import {
  Box,
  Button,
  Icon,
  Stack,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import DoneAllIcon from '@mui/icons-material/DoneAll';
import { toolDisplayNames, ToolEnum, toolOrder } from "@/user/UserRestrictions/common";
import isNil from "@/utis/isNil";
import { useUser } from "@/user/UserContext";
import { PackageTypeEnum } from "@/user/common";
import { useRouter } from "@/navigation";
import SensorsIcon from "@mui/icons-material/Sensors"
import { useUserSettingsContextSelector } from "@/user/user-settings/UserSettingsContext";
import { TranslationValues, useFormatter, useTranslations } from "next-intl";
import useGetConstants from "@/hooks/useGetConstants";
import useFetchUserPaymentPortal from "@/hooks/useFetchUserPaymentPortal";
import { GET_PAYMENT_URL, GetPaymentUrl, GetPaymentUrlVariables, getPackageName, AvailablePlansProps } from "./common";
import ErrorBoundaryPopup from "../ErrorBoundary/ErrorBoundaryPopup";
import PageSpinner from "../PageSpinner";
import StyledTypography from "../StyledComponents/EllipseTypography";
import InfoModalModal from "../InfoModal/InfoModalModal";
import { LoginType } from "../Login/LoginPopup/common";
import { StyledModalButton } from "../PageFilter/common";

const StyledStackMain = styled(Stack)<{ isCurrentPlan: boolean }>(({ theme, isCurrentPlan }) => ({
  position: 'relative',
  border: isCurrentPlan ? `2px solid ${theme.palette.text.primary}` : `1px solid ${theme.palette.grayScale.twelevepercent}`,
  background: theme.palette.customBackground.cards,
  borderRadius: "16px",
  backgroundClip: "padding-box",
  boxShadow: "none",
  marginBottom: "16px",
  width: 282,
}));

const StyledStackHeader = styled(Stack)<{ isCurrentPlan: boolean }>(({ theme, isCurrentPlan }) => ({
  justifyContent: "space-between",
  borderRadius: "16px",
  width: '100%',
  margin: '0px',
  gap: "24px",
}));



const StyledButton = styled(Button, {
  shouldForwardProp: (propName) => propName !== "textColor",
})<{ textColor?: string }>(
  ({ theme, textColor }) => `
  height: 52px;
  font-size: ${theme.typography.body2.fontSize};
  font-weight: bold;
  width: 100%;
  border-radius: 6px;
  &.Mui-disabled {
    color: ${textColor || theme.palette.text.primary};
  }
  
`,
);

function getRestrictionsLimitText(limit: number | undefined | null, per?: boolean, text?: string) {
  return (
    <Stack direction="row" alignItems="center" gap={1}>
      <DoneAllIcon sx={{ fontSize: 16, color: "text.secondary" }} />
      <Typography variant="body2" color="text.secondary">
        {text}:
      </Typography>
      <Typography variant="body2" color="text.primary" fontWeight={700}>
        {limit === 0 ? "None" : limit ?? "None"}
        {limit && per && limit !== 0 ? "%" : ""}
      </Typography>
    </Stack>
  );
};


function AvailablePlansDesktop({ isTrial, renewTime, hideTopBtn, ...restProps }: AvailablePlansProps) {
  const user = useUser();
  const theme = useTheme();
  const { data: constants, loading: constantLoading } = useGetConstants();
  const openLoginPopup = useUserSettingsContextSelector((ctx) => ctx.openLoginPopup);
  const router = useRouter();
  const restrictions = constants?.restrictions?.filter((res) => res.package !== "LOGGED_IN");

  const [packagedClicked, setpackagedClicked] = useState<PackageTypeEnum>();
  const [openInfo, setOpenInfo] = useState(false);
  const handleOpenInfo = () => {
    setOpenInfo(true);
  };

  const [fetchPaymentUrl, { loading: fetchPaymentUrlLoading }] = useMutation<GetPaymentUrl, GetPaymentUrlVariables>(
    GET_PAYMENT_URL,
  );

  const userHasNoActivePackage =
    isNil(user.data?.activePackageType) ||
    user.data?.activePackageType === PackageTypeEnum.ANONYMOUS ||
    user.data?.activePackageType === PackageTypeEnum.LOGGED_IN;

  const { redirectUserToPaymentPortal, loading: paymentPortalLoading } = useFetchUserPaymentPortal();

  const redirectUserToPayment = (packageType: PackageTypeEnum) => {
    if (userHasNoActivePackage) {
      fetchPaymentUrl({ variables: { packageType } })
        .then((resData) => {
          const paymentUrl = resData.data?.user.purchase;
          if (paymentUrl) {
            router.push(paymentUrl);
          }
        })
        .catch((err) => <ErrorBoundaryPopup message={err.message} />);
    }
  };

  const handleCloseInfo = () => {
    setOpenInfo(false);
    if (packagedClicked) {
      redirectUserToPayment(packagedClicked);
    }
    setpackagedClicked(undefined);
  };

  // const isPackageGreaterThenCurrent = (packageType: PackageTypeEnum) => {
  //   const currPackage = user.data?.activePackageType || PackageTypeEnum.ANONYMOUS;
  //   const currPackageIndex = Object.values(PackageTypeEnum).findIndex((val) => val === currPackage);
  //   const packageIndex = Object.values(PackageTypeEnum).findIndex((val) => val === packageType);
  //   return packageIndex > currPackageIndex;
  // };

  const onPackageTypeClick = (packageType: PackageTypeEnum, isCancel?: boolean) => {
    if (!user.isLoggedIn) {
      openLoginPopup(LoginType.SIGN_UP);
      return;
    }
    if (!userHasNoActivePackage && packageType !== (user.data?.activePackageType || PackageTypeEnum.ANONYMOUS)) {
      handleOpenInfo();
      return;
    }

    if (isCancel) {
      redirectUserToPaymentPortal();
    } else {
      redirectUserToPayment(packageType);
    }
  };

  const getTrialDaysText = (t: (key: string, values?: TranslationValues) => string, restriction: { price: number | null; trialDays: number | null }) => {
    if (restriction.price === null) {
      return "Indefinite";
    }
    if (isNil(restriction.trialDays)) {
      return "None";
    }
    return t("n_days", { value: restriction.trialDays });
  };

  const getButtonText = (isCurrentPlan: boolean, isBottom?: boolean) => {
    if (isCurrentPlan) {
      if (!user.isLoggedIn) return "free";
      if (isBottom) return "cancel_plan";
      return "active";
    }
    // if (isPackageGreaterThenCurrent(packageType)) {
    //   return "Upgrade";
    // }
    return user.isLoggedIn ? "change_plan" : "sign_up";
  };

  const t = useTranslations("plans");
  const wt = useTranslations("words");
  const format = useFormatter();

  return (
    <Stack direction="row" gap={2} margin="0 auto" p={2} position="relative" {...restProps}>
      {restrictions?.map((restriction, i) => {
        const isCurrentPlan = user?.isLoggedIn && (user.data?.activePackageType || PackageTypeEnum.ANONYMOUS) === restriction.package;

        return (
          <StyledStackMain key={i} p={2} isCurrentPlan={isCurrentPlan} >
            <StyledStackHeader
              isCurrentPlan={isCurrentPlan}
            >
              <Stack direction="row" justifyContent="space-between" alignItems="center" width="100%">
                <StyledTypography
                  variant="karantinaHeader"
                  fontSize={20}
                  sx={{ backgroundColor: theme.palette.grayScale.sixpercent, borderRadius: "8px", padding: "8px", alignSelf: 'baseline' }}
                  textAlign="center"
                >
                  {getPackageName(restriction.package)}
                </StyledTypography>
                {isCurrentPlan && <Typography variant="label" textTransform="uppercase" color="text.secondary">
                  Current plan
                </Typography>}
              </Stack>
              <Stack gap={2}>
                <Stack direction="row" alignItems="baseline" gap={0.5} pb={2} marginRight="auto"
                  sx={{ borderBottom: `1px solid ${theme.palette.grayScale.twelevepercent}`, width: "100%" }}>
                  <Typography variant="body1Bold" fontSize={32}>
                    ${format.number(restriction.price ?? 0, { currency: "USD", maximumFractionDigits: 0 })}
                  </Typography>
                  <Typography variant="label" textTransform="uppercase" color="text.secondary">{t("month")}</Typography>
                </Stack>
                <StyledButton
                  fullWidth
                  variant="outlined"
                  color={isCurrentPlan ? "error" : "secondary"}
                  size="small"
                  disabled={fetchPaymentUrlLoading || paymentPortalLoading}
                  onClick={() => onPackageTypeClick(restriction.package, isCurrentPlan)}
                >
                  <Typography variant="body1Bold" color={isCurrentPlan ? "error" : "text.primary"}>
                    {t(getButtonText(isCurrentPlan))}
                  </Typography>
                </StyledButton>
              </Stack>
            </StyledStackHeader>
            <Stack py={1.5} gap={1}>
              <Stack direction="row" alignItems="center" gap={1}>
                <DoneAllIcon sx={{ fontSize: 16, color: theme.palette.text.secondary }} />
                <Typography variant="body2" color="text.secondary">
                  {t("free_bet_converter")}
                </Typography>
              </Stack>

              <Stack direction="row" alignItems="center" gap={0.5}>
                <DoneAllIcon sx={{ fontSize: 16, color: theme.palette.text.secondary }} />
                <Typography variant="body2" color="text.secondary" ml={0.5}>
                  {t("pre_live_odds")}
                </Typography>
                <Icon color="error" sx={{
                  backgroundColor: theme.palette.grayScale.sixpercent,
                  borderRadius: "8px"
                }}>
                  <SensorsIcon sx={{ width: "20px", mb: "4px" }} />
                </Icon>
                <Typography variant="body2" color="text.secondary">
                  {t("Live_data")}
                </Typography>
              </Stack>


              <Stack direction="row" alignItems="center" gap={1}>
                <DoneAllIcon sx={{ fontSize: 16, color: theme.palette.text.secondary }} />
                <Typography variant="body2" color="text.secondary">
                  {t("portable_filters")}
                </Typography>
              </Stack>


              <Stack direction="row" alignItems="center" gap={1} key={i}>
                <DoneAllIcon sx={{ fontSize: 16, color: theme.palette.text.secondary }} />
                <Typography variant="body2" color="text.secondary">
                  {t("trial_included")}:
                </Typography>
                <Typography variant="body2" color="text.primary" fontWeight="bold">
                  {getTrialDaysText(t, restriction)}
                </Typography>
              </Stack>
            </Stack>
            {toolOrder.map((tool, ind) => {
              if (tool === ToolEnum.FREE_BET_CONVERTER) return null;
              if (tool === ToolEnum.ODDS_SCREEN) {
                return (
                  <Stack flexDirection="column" key={ind} gap={0.5} pt={1.5} pb={2.5}>
                    <Typography variant="label" textTransform="uppercase" color="text.primary">
                      {toolDisplayNames[tool]}
                    </Typography>
                    <Stack direction="row" alignItems="center" gap={1}>
                      <DoneAllIcon sx={{ fontSize: 16, color: theme.palette.text.secondary }} />
                      {i === 0 ? (
                        <><Typography variant="body2" color="text.secondary">
                          {t("refresh_time")}:
                        </Typography><Typography variant="body2" fontWeight="bold" color="text.primary">
                            {t("15_Second_Refresh")}
                          </Typography></>
                      ) : (
                        <><Typography variant="body2" color="text.secondary">
                          {t("refresh_time")}:
                        </Typography><Typography variant="body2" fontWeight="bold" color="text.primary">
                            {t("2_4_Second_Refresh")}
                          </Typography></>
                      )}
                    </Stack>
                  </Stack>
                );
              }
              const currRestriction = restriction.restrictions.find((res) => res.toolEnum === tool);
              return (
                <Stack flexDirection="column" key={ind} gap={0.5} pb={2.5}>
                  <Typography variant="label" textTransform="uppercase" color="text.primary">
                    {toolDisplayNames[tool]}
                  </Typography>
                  {getRestrictionsLimitText(currRestriction?.rOILimit, true, t("roi_limit"))}
                  {getRestrictionsLimitText(currRestriction?.maxResult, false, t("max_results"))}
                  {getRestrictionsLimitText(currRestriction?.sportbookLimit, false, t("sportsbook_limit"))}
                  {getRestrictionsLimitText(currRestriction?.leagueLimit, false, t("leagues_limit"))}

                </Stack>
              );
            })}

            <Stack gap={1}>
              <Typography variant="label" textTransform="uppercase" color="text.primary">
                {t("device_limit")}
              </Typography>
              <Stack direction="row" alignItems="center" gap={1}>
                <DoneAllIcon sx={{ fontSize: 16, color: theme.palette.text.secondary }} />
                <Typography variant="body2" color="text.secondary">
                  {t("device_limit")}:
                </Typography>
                <Typography variant="body2" color="text.primary" fontWeight="bold">
                  1
                </Typography>
              </Stack>
            </Stack>

            <StyledButton
              fullWidth
              variant="outlined"
              color={isCurrentPlan ? "error" : "secondary"}
              size="small"
              sx={{
                mt: 2,
              }}
              disabled={fetchPaymentUrlLoading || paymentPortalLoading}
              onClick={() => onPackageTypeClick(restriction.package, isCurrentPlan)}
            >
              <Typography variant="body1Bold" color={isCurrentPlan ? "error" : "secondary"}>
                {t(getButtonText(isCurrentPlan))}
              </Typography>
            </StyledButton>
          </StyledStackMain>
        );
      })}
      {constantLoading && (
        <Box position="absolute" margin="auto" width="100%" height="100%" sx={{ backdropFilter: "blur(2px)" }}>
          <PageSpinner text="Loading" />
        </Box>
      )}

      <InfoModalModal open={Boolean(openInfo)} onClose={handleCloseInfo} textAlign="center" sx={{
        padding: "32px",
      }}>
        <Typography variant="header" fontSize={30} textAlign="center" mt={2}>
          {t("contact_us_title")}
        </Typography>
        <Typography variant="body1" textAlign="center" mt={2}>
          {t("contact_us")}
        </Typography>
        <StyledModalButton
          variant="contained"
          color="secondary"
          sx={{
            mt: 2,
          }}
          onClick={handleCloseInfo}
        >
          {wt("close")}
        </StyledModalButton>
      </InfoModalModal>
    </Stack>
  );
}

export default AvailablePlansDesktop;

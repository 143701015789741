import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Icon,
  Stack,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import SensorsIcon from "@mui/icons-material/Sensors"
import DoneAllIcon from '@mui/icons-material/DoneAll';
import { toolDisplayNames, ToolEnum, toolOrder } from "@/user/UserRestrictions/common";
import isNil from "@/utis/isNil";
import { useUser } from "@/user/UserContext";
import { PackageTypeEnum } from "@/user/common";
import { useRouter } from "@/navigation";
import { useUserSettingsContextSelector } from "@/user/user-settings/UserSettingsContext";
import { TranslationValues, useFormatter, useTranslations } from "next-intl";
import useGetConstants from "@/hooks/useGetConstants";
import useFetchUserPaymentPortal from "@/hooks/useFetchUserPaymentPortal";
import { GET_PAYMENT_URL, GetPaymentUrl, GetPaymentUrlVariables, getPackageName, AvailablePlansProps } from "./common";
import ErrorBoundaryPopup from "../ErrorBoundary/ErrorBoundaryPopup";
import PageSpinner from "../PageSpinner";
import StyledTypography from "../StyledComponents/EllipseTypography";
import InfoModalModal from "../InfoModal/InfoModalModal";
import { LoginType } from "../Login/LoginPopup/common";

const StyledAccordian = styled(Accordion)<{ isCurrentPlan: boolean }>(({ theme, isCurrentPlan }) => ({
  position: 'relative',
  border: isCurrentPlan ? `2px solid ${theme.palette.text.primary}` : `1px solid ${theme.palette.grayScale.twelevepercent}`,
  background: theme.palette.customBackground.cards,
  borderRadius: "16px",
  backgroundClip: "padding-box",
  boxShadow: "none",
  marginBottom: "16px",
  '&::before': {
    display: 'none',
  },
  '&:first-of-type': {
    borderRadius: "16px !important",
  },
  '&:last-of-type': {
    borderRadius: "16px !important",
  },

}));

const StyledAccordionSummary = styled(AccordionSummary)<{ isCurrentPlan: boolean }>(({ theme, isCurrentPlan }) => ({
  position: 'relative',
  paddingLeft: 0,
  paddingRight: 0,
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  borderRadius: "16px",
  backgroundClip: "padding-box",

  '.MuiAccordionSummary-content': {
    flexDirection: 'column',
    width: '100%',
    margin: '0px',
    padding: '16px 16px 0px 16px',
    gap: "24px",

  },
}));



const StyledButton = styled(Button, {
  shouldForwardProp: (propName) => propName !== "textColor",
})<{ textColor?: string }>(
  ({ theme, textColor }) => `
  height: 36px;
  font-size: ${theme.typography.body2.fontSize};
  font-weight: bold;
  border-radius: 6px;
  width: 140px;
  ${theme.breakpoints.down("md")} {
    padding: ${theme.spacing(0.5)};
  }
  &.Mui-disabled {
    color: ${textColor || theme.palette.text.primary};
  }
  
`,
);


function getRestrictionsLimitText(limit: number | undefined | null, per?: boolean, text?: string) {
  return (
    <Stack direction="row" alignItems="center" gap={1}>
      <DoneAllIcon sx={{ fontSize: 16, color: "text.secondary" }} />
      <Typography variant="body2" color="text.secondary">
        {text}:
      </Typography>
      <Typography variant="body2" color="text.primary" fontWeight={700}>
        {limit === 0 ? "None" : limit ?? "None"}
        {limit && per && limit !== 0 ? "%" : ""}
      </Typography>
    </Stack>
  );
};


const getTrialDaysText = (t: (key: string, values?: TranslationValues) => string, restriction: { price: number | null; trialDays: number | null }) => {
  if (restriction.price === null) {
    return "Indefinite";
  }
  if (isNil(restriction.trialDays)) {
    return "None";
  }
  return t("n_days", { value: restriction.trialDays });
};


function AvailablePlansMobile({ isTrial, renewTime, hideTopBtn, ...restProps }: AvailablePlansProps) {
  const user = useUser();
  const theme = useTheme();
  const { data: constants, loading: constantLoading } = useGetConstants();
  const openLoginPopup = useUserSettingsContextSelector((ctx) => ctx.openLoginPopup);
  const router = useRouter();
  const restrictions = constants?.restrictions?.filter((res) => res.package !== "LOGGED_IN");

  const [packagedClicked, setpackagedClicked] = useState<PackageTypeEnum>();
  const [openInfo, setOpenInfo] = useState(false);
  const handleOpenInfo = () => {
    setOpenInfo(true);
  };

  const [fetchPaymentUrl, { loading: fetchPaymentUrlLoading }] = useMutation<GetPaymentUrl, GetPaymentUrlVariables>(
    GET_PAYMENT_URL,
  );

  const userHasNoActivePackage =
    isNil(user.data?.activePackageType) ||
    user.data?.activePackageType === PackageTypeEnum.ANONYMOUS ||
    user.data?.activePackageType === PackageTypeEnum.LOGGED_IN;

  const { redirectUserToPaymentPortal, loading: paymentPortalLoading } = useFetchUserPaymentPortal();

  const redirectUserToPayment = (packageType: PackageTypeEnum) => {
    if (userHasNoActivePackage) {
      fetchPaymentUrl({ variables: { packageType } })
        .then((resData) => {
          const paymentUrl = resData.data?.user.purchase;
          if (paymentUrl) {
            router.push(paymentUrl);
          }
        })
        .catch((err) => <ErrorBoundaryPopup message={err.message} />);
    }
  };

  const handleCloseInfo = () => {
    setOpenInfo(false);
    if (packagedClicked) {
      redirectUserToPayment(packagedClicked);
    }
    setpackagedClicked(undefined);
  };

  const onPackageTypeClick = (packageType: PackageTypeEnum, isRedirect?: boolean) => {
    if (!user.isLoggedIn) {
      openLoginPopup(LoginType.SIGN_UP);
      return;
    }
    if (!userHasNoActivePackage && packageType !== (user.data?.activePackageType || PackageTypeEnum.ANONYMOUS)) {
      handleOpenInfo();
      return;
    }

    if (isRedirect) {
      redirectUserToPaymentPortal();
    } else {
      redirectUserToPayment(packageType);
    }
  };

  const getButtonText = (isCurrentPlan: boolean, isBottom?: boolean) => {
    if (isCurrentPlan) {
      if (!user.isLoggedIn) return "free";
      if (isBottom) return "cancel_plan";
      return "active";
    }
    return user.isLoggedIn ? "change_plan" : "sign_up";
  };

  const t = useTranslations("plans");
  const wt = useTranslations("words");
  const format = useFormatter();
  const [expanded, setExpanded] = React.useState<string | false>(false);

  useEffect(() => {
    const currPackageIndedx = restrictions?.findIndex(
      (restriction) => (user.data?.activePackageType || PackageTypeEnum.ANONYMOUS) === restriction.package,
    );
    if (currPackageIndedx !== undefined && expanded === undefined) {
      setExpanded(`panel${currPackageIndedx}`);
    }
  }, [expanded, restrictions, setExpanded, user.data?.activePackageType]);

  const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <Stack direction="column" position="relative" {...restProps}>
      {restrictions?.map((restriction, i) => {
        const isCurrentPlan = user?.isLoggedIn && (user.data?.activePackageType || PackageTypeEnum.ANONYMOUS) === restriction.package;
        return (
          <StyledAccordian key={i} isCurrentPlan={isCurrentPlan} expanded={expanded === `panel${i}`} onChange={handleChange(`panel${i}`)} disableGutters>
            <StyledAccordionSummary
              aria-controls={`panel${i}d-content`}
              id={`panel${i}d-header`}
              isCurrentPlan={isCurrentPlan}
            >
              <Stack direction="row" justifyContent="space-between" width="100%">
                <StyledTypography
                  variant="karantinaHeader"
                  fontSize={20}
                  sx={{ backgroundColor: theme.palette.grayScale.sixpercent, borderRadius: "8px", padding: "8px", alignSelf: 'baseline' }}
                  textAlign="center"
                >
                  {getPackageName(restriction.package)}
                </StyledTypography>

                <StyledButton
                  fullWidth
                  variant="outlined"
                  color={isCurrentPlan ? "error" : "secondary"}
                  size="small"
                  disabled={fetchPaymentUrlLoading || paymentPortalLoading}
                  onClick={() => onPackageTypeClick(restriction.package, isCurrentPlan)}
                >
                  <Typography variant="label" color={isCurrentPlan ? "error" : "secondary"}>
                    {t(getButtonText(isCurrentPlan))}
                  </Typography>
                </StyledButton>
              </Stack>

              <Stack>
                <Stack direction="row" alignItems="baseline" gap={0.5} pb={1} marginRight="auto"
                  sx={{ borderBottom: `1px solid ${theme.palette.grayScale.twelevepercent}`, width: "100%" }}>
                  <Typography variant="body1Bold" fontSize={32}>
                    ${format.number(restriction.price ?? 0, { currency: "USD", maximumFractionDigits: 0 })}
                  </Typography>
                  <Typography variant="label" textTransform="uppercase" color="text.secondary">{t("month")}</Typography>
                </Stack>
                {!(expanded === `panel${i}`) && (
                  <Stack direction="row" justifyContent="center" p={1} alignItems="center">
                    <Typography variant="body2" color="text.secondary">
                      See Benefits
                    </Typography>
                    <ArrowDropDownIcon color="secondary" />
                  </Stack>
                )}
              </Stack>
            </StyledAccordionSummary>
            <AccordionDetails>
              <Stack py={1.5} gap={1}>
                <Stack direction="row" alignItems="center" gap={1}>
                  <DoneAllIcon sx={{ fontSize: 16, color: theme.palette.text.secondary }} />
                  <Typography variant="body2" color="text.secondary">
                    {t("free_bet_converter")}
                  </Typography>
                </Stack>

                <Stack direction="row" alignItems="center" gap={0.5}>
                  <DoneAllIcon sx={{ fontSize: 16, color: theme.palette.text.secondary }} />
                  <Typography variant="body2" color="text.secondary" ml={0.5}>
                    {t("pre_live_odds")}
                  </Typography>
                  <Icon color="error" sx={{
                    backgroundColor: theme.palette.grayScale.sixpercent,
                    borderRadius: "8px"
                  }}>
                    <SensorsIcon sx={{ width: "20px", mb: "4px" }} />
                  </Icon>
                  <Typography variant="body2" color="text.secondary">
                    {t("Live_data")}
                  </Typography>
                </Stack>


                <Stack direction="row" alignItems="center" gap={1}>
                  <DoneAllIcon sx={{ fontSize: 16, color: theme.palette.text.secondary }} />
                  <Typography variant="body2" color="text.secondary">
                    {t("portable_filters")}
                  </Typography>
                </Stack>


                <Stack direction="row" alignItems="center" gap={1} key={i}>
                  <DoneAllIcon sx={{ fontSize: 16, color: theme.palette.text.secondary }} />
                  <Typography variant="body2" color="text.secondary">
                    {t("trial_included")}:
                  </Typography>
                  <Typography variant="body2" color="text.primary" fontWeight="bold">
                    {getTrialDaysText(t, restriction)}
                  </Typography>
                </Stack>
              </Stack>
              {toolOrder.map((tool, ind) => {
                if (tool === ToolEnum.FREE_BET_CONVERTER) return null;
                if (tool === ToolEnum.ODDS_SCREEN) {
                  return (
                    <Stack flexDirection="column" key={ind} gap={0.5} pt={1.5} pb={2.5}>
                      <Typography variant="label" textTransform="uppercase" color="text.primary">
                        {toolDisplayNames[tool]}
                      </Typography>
                      <Stack direction="row" alignItems="center" gap={1}>
                        <DoneAllIcon sx={{ fontSize: 16, color: theme.palette.text.secondary }} />
                        {i === 0 ? (
                          <><Typography variant="body2" color="text.secondary">
                            {t("refresh_time")}:
                          </Typography><Typography variant="body2" fontWeight="bold" color="text.primary">
                              {t("15_Second_Refresh")}
                            </Typography></>
                        ) : (
                          <><Typography variant="body2" color="text.secondary">
                            {t("refresh_time")}:
                          </Typography><Typography variant="body2" fontWeight="bold" color="text.primary">
                              {t("2_4_Second_Refresh")}
                            </Typography></>
                        )}
                      </Stack>
                    </Stack>
                  );
                }
                const currRestriction = restriction.restrictions.find((res) => res.toolEnum === tool);
                return (
                  <Stack flexDirection="column" key={ind} gap={0.5} pb={2.5}>
                    <Typography variant="label" textTransform="uppercase" color="text.primary">
                      {toolDisplayNames[tool]}
                    </Typography>
                    {getRestrictionsLimitText(currRestriction?.rOILimit, true, t("roi_limit"))}
                    {getRestrictionsLimitText(currRestriction?.maxResult, false, t("max_results"))}
                    {getRestrictionsLimitText(currRestriction?.sportbookLimit, false, t("sportsbook_limit"))}
                    {getRestrictionsLimitText(currRestriction?.leagueLimit, false, t("leagues_limit"))}

                  </Stack>
                );
              })}

              <Stack gap={1}>
                <Typography variant="label" textTransform="uppercase" color="text.primary">
                  {t("device_limit")}
                </Typography>
                <Stack direction="row" alignItems="center" gap={1}>
                  <DoneAllIcon sx={{ fontSize: 16, color: theme.palette.text.secondary }} />
                  <Typography variant="body2" color="text.secondary">
                    {t("device_limit")}:
                  </Typography>
                  <Typography variant="body2" color="text.primary" fontWeight="bold">
                    1
                  </Typography>
                </Stack>
              </Stack>
              <Stack width="100%" p={2}>
                <StyledButton
                  fullWidth
                  variant="outlined"
                  color={isCurrentPlan ? "error" : "secondary"}
                  size="small"
                  sx={{
                    width: "100%",
                    height: "36px",
                  }}
                  disabled={fetchPaymentUrlLoading || paymentPortalLoading}
                  onClick={() => onPackageTypeClick(restriction.package, isCurrentPlan)}
                >
                  <Typography variant="body1" color={isCurrentPlan ? "error" : "secondary"}>
                    {t(getButtonText(isCurrentPlan))}
                  </Typography>
                </StyledButton>
              </Stack>
              <Stack direction="row" justifyContent="center" p={1} alignItems="center" onClick={() => setExpanded(expanded === `panel${i}` ? false : `panel${i}`)}
                sx={{ borderTop: `1px solid ${theme.palette.grayScale.twelevepercent}` }}>
                <Typography variant="body2" color="text.secondary">
                  Close Benefits
                </Typography>
                <ArrowDropUpIcon color="secondary" />
              </Stack>
            </AccordionDetails>
          </StyledAccordian>
        );
      })}
      {constantLoading && (
        <Box position="absolute" margin="auto" width="100%" height="100%" sx={{ backdropFilter: "blur(2px)" }}>
          <PageSpinner text="Loading" />
        </Box>
      )}

      <InfoModalModal open={Boolean(openInfo)} onClose={handleCloseInfo} width={413} textAlign="center" hideCloseBtn>
        <Typography variant="header" textAlign="center" mt={2}>
          {t("contact_us_title")}
        </Typography>
        <Typography variant="body1" textAlign="center" mt={2}>
          {t("contact_us")}
        </Typography>
        <Button
          variant="outlined"
          sx={{ borderColor: theme.palette.text.secondary, textTransform: "none", mt: 2 }}
          onClick={handleCloseInfo}
        >
          <Typography variant="label" color="text.secondary">
            {wt("close")}
          </Typography>
        </Button>
      </InfoModalModal>
    </Stack>
  );
}

export default AvailablePlansMobile;
